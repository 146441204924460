/* Main Variables */
:root {
  --main-font-family: Mukta, sans-serif;
  --secondary-font-family: Figtree, sans-serif;
  --primary-color: #b8cbd9;
  --danger-color: #c70039;
  --dark-font: #1d1d1f;
  --light-font: #6c7680;
  --danger-color-70pc: rgba(199, 0, 57, 0.7);
  --main-background: #ebebeb;
  --danger-background: #ff2638;
  --danger-background-80pc: rgba(255, 38, 56, 0.8);
  --danger-background-30pc: rgba(255, 38, 56, 0.3);
  --form-error: #ffa926;
  --toastr-success: #22b53a;
  --btn-radius: 5px;
  --avatar-radius: 5px;
  --lpt-paid-principal: #0c46a0;
  --lpt-future-interest: #f4f6f7;
  --lpt-paid-interest: #2681ff;
}

/* Main Variables */
:root {
  --main-font-family: Mukta, sans-serif;
  --secondary-font-family: Figtree, sans-serif;
  --primary-color: #b8cbd9;
  --danger-color: #c70039;
  --dark-font: #1d1d1f;
  --light-font: #6c7680;
  --danger-color-70pc: rgba(199, 0, 57, 0.7);
  --main-background: #ebebeb;
  --danger-background: #ff2638;
  --danger-background-80pc: rgba(255, 38, 56, 0.8);
  --danger-background-30pc: rgba(255, 38, 56, 0.3);
  --form-error: #ffa926;
  --toastr-success: #22b53a;
  --btn-radius: 5px;
  --avatar-radius: 5px;
  --lpt-paid-principal: #0c46a0;
  --lpt-future-interest: #f4f6f7;
  --lpt-paid-interest: #2681ff;
}

body {
  min-width: 320px;
  font-size: 16px;
  font-family: var(--main-font-family);
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  background-color: var(--main-background);
  color: var(--light-font);
  -webkit-font-smoothing: antialiased;
}

*,
::after,
::before {
  box-sizing: border-box;
}

.hr {
  display: block;
  max-width: 670px;
  height: 1px;
  margin: 0 auto;
  border: none;
  background: #b8cbd9;
  opacity: 0.5;
}

.no-display {
  display: none;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}
.flex-1 .justify-center {
  justify-content: center;
}
.flex-1 .align-conter {
  align-items: center;
}

/* Main Variables */
:root {
  --main-font-family: Mukta, sans-serif;
  --secondary-font-family: Figtree, sans-serif;
  --primary-color: #b8cbd9;
  --danger-color: #c70039;
  --dark-font: #1d1d1f;
  --light-font: #6c7680;
  --danger-color-70pc: rgba(199, 0, 57, 0.7);
  --main-background: #ebebeb;
  --danger-background: #ff2638;
  --danger-background-80pc: rgba(255, 38, 56, 0.8);
  --danger-background-30pc: rgba(255, 38, 56, 0.3);
  --form-error: #ffa926;
  --toastr-success: #22b53a;
  --btn-radius: 5px;
  --avatar-radius: 5px;
  --lpt-paid-principal: #0c46a0;
  --lpt-future-interest: #f4f6f7;
  --lpt-paid-interest: #2681ff;
}

h1, h2, h3, h4, h5 {
  font-weight: normal;
  color: var(--dark-font);
}

h1 {
  line-height: 1.2;
  font-weight: 600;
  font-size: 40px;
}

h2 {
  line-height: 1.4;
  font-weight: 600;
  font-size: 30px;
}
@media (max-width: 768px) {
  h2 {
    line-height: 1.19;
    font-size: 26px;
  }
}

h3 {
  line-height: 1.42;
  font-size: 24px;
}
@media (max-width: 768px) {
  h3 {
    line-height: 1.18;
    font-size: 22px;
  }
}

h4 {
  line-height: 1.4;
  font-size: 20px;
}
@media (max-width: 768px) {
  h4 {
    line-height: 1.22;
    font-size: 18px;
  }
}

h5 {
  line-height: 1.38;
  font-size: 16px;
}
@media (max-width: 768px) {
  h5 {
    line-height: 1.19;
  }
}

.number {
  font-family: var(--secondary-font-family);
  font-weight: 600;
  color: var(--dark-font);
}
.number--huge {
  font-size: 50px;
  line-height: 1.2;
}
.number--lg {
  font-size: 36px;
  line-height: 1.19;
}
.number--md {
  font-size: 32px;
  line-height: 1.19;
}
.number--sm {
  font-weight: normal;
  font-size: 20px;
  line-height: 1.2;
}
.number--sm.font-weight-600 {
  font-weight: 600;
}

/* Main Variables */
:root {
  --main-font-family: Mukta, sans-serif;
  --secondary-font-family: Figtree, sans-serif;
  --primary-color: #b8cbd9;
  --danger-color: #c70039;
  --dark-font: #1d1d1f;
  --light-font: #6c7680;
  --danger-color-70pc: rgba(199, 0, 57, 0.7);
  --main-background: #ebebeb;
  --danger-background: #ff2638;
  --danger-background-80pc: rgba(255, 38, 56, 0.8);
  --danger-background-30pc: rgba(255, 38, 56, 0.3);
  --form-error: #ffa926;
  --toastr-success: #22b53a;
  --btn-radius: 5px;
  --avatar-radius: 5px;
  --lpt-paid-principal: #0c46a0;
  --lpt-future-interest: #f4f6f7;
  --lpt-paid-interest: #2681ff;
}

p {
  font-weight: normal;
  color: var(--light-font);
}
p.text {
  line-height: 1.38;
  font-size: 16px;
}
p.text--lg {
  line-height: 1.39;
  font-size: 18px;
}

.legend-text {
  line-height: 1.14;
  font-size: 13px;
  color: var(--light-font);
}

.disclaimer-text {
  line-height: 1.36;
  font-size: 11px;
  color: var(--legend-font);
}
.disclaimer-text a {
  text-decoration: underline;
  color: #2681ff;
}

.caption-text {
  line-height: 1.23;
  font-size: 13px;
  color: var(--light-font);
}

.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-italic {
  font-style: italic;
}

.f-w-600 {
  font-weight: 600;
}

.f-w-900 {
  font-weight: 900;
}

.f-11-normal {
  font-size: 11px !important;
  font-weight: normal !important;
}

.f-11-400 {
  font-size: 11px !important;
  font-weight: 400 !important;
}

.f-11-500 {
  font-size: 11px !important;
  font-weight: 500 !important;
}

.f-11-600 {
  font-size: 11px !important;
  font-weight: 600 !important;
}

.f-11-700 {
  font-size: 11px !important;
  font-weight: 700 !important;
}

.f-11-bold {
  font-size: 11px !important;
  font-weight: bold !important;
}

.f-12-normal {
  font-size: 12px !important;
  font-weight: normal !important;
}

.f-12-400 {
  font-size: 12px !important;
  font-weight: 400 !important;
}

.f-12-500 {
  font-size: 12px !important;
  font-weight: 500 !important;
}

.f-12-600 {
  font-size: 12px !important;
  font-weight: 600 !important;
}

.f-12-700 {
  font-size: 12px !important;
  font-weight: 700 !important;
}

.f-12-bold {
  font-size: 12px !important;
  font-weight: bold !important;
}

.f-13-normal {
  font-size: 13px !important;
  font-weight: normal !important;
}

.f-13-400 {
  font-size: 13px !important;
  font-weight: 400 !important;
}

.f-13-500 {
  font-size: 13px !important;
  font-weight: 500 !important;
}

.f-13-600 {
  font-size: 13px !important;
  font-weight: 600 !important;
}

.f-13-700 {
  font-size: 13px !important;
  font-weight: 700 !important;
}

.f-13-bold {
  font-size: 13px !important;
  font-weight: bold !important;
}

.f-16-normal {
  font-size: 16px !important;
  font-weight: normal !important;
}

.f-16-400 {
  font-size: 16px !important;
  font-weight: 400 !important;
}

.f-16-500 {
  font-size: 16px !important;
  font-weight: 500 !important;
}

.f-16-600 {
  font-size: 16px !important;
  font-weight: 600 !important;
}

.f-16-700 {
  font-size: 16px !important;
  font-weight: 700 !important;
}

.f-16-bold {
  font-size: 16px !important;
  font-weight: bold !important;
}

.f-18-normal {
  font-size: 18px !important;
  font-weight: normal !important;
}

.f-18-400 {
  font-size: 18px !important;
  font-weight: 400 !important;
}

.f-18-500 {
  font-size: 18px !important;
  font-weight: 500 !important;
}

.f-18-600 {
  font-size: 18px !important;
  font-weight: 600 !important;
}

.f-18-700 {
  font-size: 18px !important;
  font-weight: 700 !important;
}

.f-18-bold {
  font-size: 18px !important;
  font-weight: bold !important;
}

.f-20-normal {
  font-size: 20px !important;
  font-weight: normal !important;
}

.f-20-400 {
  font-size: 20px !important;
  font-weight: 400 !important;
}

.f-20-500 {
  font-size: 20px !important;
  font-weight: 500 !important;
}

.f-20-600 {
  font-size: 20px !important;
  font-weight: 600 !important;
}

.f-20-700 {
  font-size: 20px !important;
  font-weight: 700 !important;
}

.f-20-bold {
  font-size: 20px !important;
  font-weight: bold !important;
}

.f-24-normal {
  font-size: 24px !important;
  font-weight: normal !important;
}

.f-24-400 {
  font-size: 24px !important;
  font-weight: 400 !important;
}

.f-24-500 {
  font-size: 24px !important;
  font-weight: 500 !important;
}

.f-24-600 {
  font-size: 24px !important;
  font-weight: 600 !important;
}

.f-24-700 {
  font-size: 24px !important;
  font-weight: 700 !important;
}

.f-24-bold {
  font-size: 24px !important;
  font-weight: bold !important;
}

.f-26-normal {
  font-size: 26px !important;
  font-weight: normal !important;
}

.f-26-400 {
  font-size: 26px !important;
  font-weight: 400 !important;
}

.f-26-500 {
  font-size: 26px !important;
  font-weight: 500 !important;
}

.f-26-600 {
  font-size: 26px !important;
  font-weight: 600 !important;
}

.f-26-700 {
  font-size: 26px !important;
  font-weight: 700 !important;
}

.f-26-bold {
  font-size: 26px !important;
  font-weight: bold !important;
}

.f-30-normal {
  font-size: 30px !important;
  font-weight: normal !important;
}

.f-30-400 {
  font-size: 30px !important;
  font-weight: 400 !important;
}

.f-30-500 {
  font-size: 30px !important;
  font-weight: 500 !important;
}

.f-30-600 {
  font-size: 30px !important;
  font-weight: 600 !important;
}

.f-30-700 {
  font-size: 30px !important;
  font-weight: 700 !important;
}

.f-30-bold {
  font-size: 30px !important;
  font-weight: bold !important;
}

.f-32-normal {
  font-size: 32px !important;
  font-weight: normal !important;
}

.f-32-400 {
  font-size: 32px !important;
  font-weight: 400 !important;
}

.f-32-500 {
  font-size: 32px !important;
  font-weight: 500 !important;
}

.f-32-600 {
  font-size: 32px !important;
  font-weight: 600 !important;
}

.f-32-700 {
  font-size: 32px !important;
  font-weight: 700 !important;
}

.f-32-bold {
  font-size: 32px !important;
  font-weight: bold !important;
}

.f-34-normal {
  font-size: 34px !important;
  font-weight: normal !important;
}

.f-34-400 {
  font-size: 34px !important;
  font-weight: 400 !important;
}

.f-34-500 {
  font-size: 34px !important;
  font-weight: 500 !important;
}

.f-34-600 {
  font-size: 34px !important;
  font-weight: 600 !important;
}

.f-34-700 {
  font-size: 34px !important;
  font-weight: 700 !important;
}

.f-34-bold {
  font-size: 34px !important;
  font-weight: bold !important;
}

.f-c-east-bay {
  color: #6c7680 !important;
}

.f-c-gull-gray {
  color: #a9b0b8 !important;
}

.f-c-clear-blue {
  color: #2681ff !important;
}

.f-c-firefly {
  color: #1d1d1f !important;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

/* Main Variables */
:root {
  --main-font-family: Mukta, sans-serif;
  --secondary-font-family: Figtree, sans-serif;
  --primary-color: #b8cbd9;
  --danger-color: #c70039;
  --dark-font: #1d1d1f;
  --light-font: #6c7680;
  --danger-color-70pc: rgba(199, 0, 57, 0.7);
  --main-background: #ebebeb;
  --danger-background: #ff2638;
  --danger-background-80pc: rgba(255, 38, 56, 0.8);
  --danger-background-30pc: rgba(255, 38, 56, 0.3);
  --form-error: #ffa926;
  --toastr-success: #22b53a;
  --btn-radius: 5px;
  --avatar-radius: 5px;
  --lpt-paid-principal: #0c46a0;
  --lpt-future-interest: #f4f6f7;
  --lpt-paid-interest: #2681ff;
}

input, button {
  border: none;
}
input:focus, button:focus {
  outline: none;
}

.form-label-text {
  font-size: 16px;
  font-weight: normal;
  line-height: 1;
  color: var(--light-font);
}

.form-value-text {
  font-size: 18px;
  font-weight: normal;
  line-height: 1.33;
  color: var(--dark-font);
}

.form-placeholder-text {
  font-size: 18px;
  font-weight: normal;
  line-height: 1.33;
  color: var(--legend-font);
}

ul {
  list-style: none;
}
ul.list-unstyled {
  padding-left: 0;
}
ul.list-inline {
  display: flex;
  padding-left: 0;
}
ul.list-inline li {
  margin-right: 15px;
}

/* Main Variables */
:root {
  --main-font-family: Mukta, sans-serif;
  --secondary-font-family: Figtree, sans-serif;
  --primary-color: #b8cbd9;
  --danger-color: #c70039;
  --dark-font: #1d1d1f;
  --light-font: #6c7680;
  --danger-color-70pc: rgba(199, 0, 57, 0.7);
  --main-background: #ebebeb;
  --danger-background: #ff2638;
  --danger-background-80pc: rgba(255, 38, 56, 0.8);
  --danger-background-30pc: rgba(255, 38, 56, 0.3);
  --form-error: #ffa926;
  --toastr-success: #22b53a;
  --btn-radius: 5px;
  --avatar-radius: 5px;
  --lpt-paid-principal: #0c46a0;
  --lpt-future-interest: #f4f6f7;
  --lpt-paid-interest: #2681ff;
}

.contact-phone a {
  padding: 0;
  font-size: 20px;
  font-weight: normal;
  line-height: 1.4;
  color: var(--dark-font);
}

.contact-email a {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0;
  font-size: 20px;
  font-weight: normal;
  line-height: 1.4;
  color: var(--dark-font);
}

/* Main Variables */
:root {
  --main-font-family: Mukta, sans-serif;
  --secondary-font-family: Figtree, sans-serif;
  --primary-color: #b8cbd9;
  --danger-color: #c70039;
  --dark-font: #1d1d1f;
  --light-font: #6c7680;
  --danger-color-70pc: rgba(199, 0, 57, 0.7);
  --main-background: #ebebeb;
  --danger-background: #ff2638;
  --danger-background-80pc: rgba(255, 38, 56, 0.8);
  --danger-background-30pc: rgba(255, 38, 56, 0.3);
  --form-error: #ffa926;
  --toastr-success: #22b53a;
  --btn-radius: 5px;
  --avatar-radius: 5px;
  --lpt-paid-principal: #0c46a0;
  --lpt-future-interest: #f4f6f7;
  --lpt-paid-interest: #2681ff;
}

.color-primary {
  color: var(--primary-color) !important;
}

.color-danger {
  color: var(--danger-color) !important;
}

.color-white {
  color: #fff !important;
}

a {
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6, p, ul {
  margin: 0;
}

.pseudo-link {
  font-size: 22px;
  color: var(--primary-color);
  opacity: 1;
  transition: opacity 0.2s;
  cursor: pointer;
}
.pseudo-link:hover {
  opacity: 0.8;
}

/* Main Variables */
:root {
  --main-font-family: Mukta, sans-serif;
  --secondary-font-family: Figtree, sans-serif;
  --primary-color: #b8cbd9;
  --danger-color: #c70039;
  --dark-font: #1d1d1f;
  --light-font: #6c7680;
  --danger-color-70pc: rgba(199, 0, 57, 0.7);
  --main-background: #ebebeb;
  --danger-background: #ff2638;
  --danger-background-80pc: rgba(255, 38, 56, 0.8);
  --danger-background-30pc: rgba(255, 38, 56, 0.3);
  --form-error: #ffa926;
  --toastr-success: #22b53a;
  --btn-radius: 5px;
  --avatar-radius: 5px;
  --lpt-paid-principal: #0c46a0;
  --lpt-future-interest: #f4f6f7;
  --lpt-paid-interest: #2681ff;
}

.container {
  max-width: 832px;
  margin: 0 auto;
  padding: 80px 20px 0;
}
@media (max-width: 425px) {
  .container {
    padding: 0;
  }
}

.card {
  position: relative;
  margin-bottom: 32px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 10px 35px 0 rgba(2, 36, 71, 0.05), 0 5px 15px 0 rgba(2, 36, 71, 0.05);
}
.card.tip-label:before {
  content: "";
  position: absolute;
  top: 35px;
  left: -13px;
  width: 64px;
  height: 47px;
  background-image: url("/assets/icons/tip-label.svg");
  background-repeat: no-repeat;
}
@media (max-width: 425px) {
  .card {
    border-radius: 0;
  }
  .card.tip-label:before {
    top: -12px;
    left: 50%;
    width: 73px;
    height: 34px;
    margin-left: -36px;
    background-image: url("/assets/icons/tip-label-mobile.svg");
  }
}
.card__header {
  position: relative;
  padding: 64px 20px 40px;
  border-radius: 9px 9px 0 0;
}
.card__header-user {
  position: absolute;
  top: -45px;
  left: 50%;
  width: 90px;
  height: 90px;
  padding: 5px;
  border-radius: 50%;
  overflow: hidden;
  background-color: var(--main-background);
  transform: translateX(-50%);
}
.card__header-title {
  max-width: 600px;
  margin: 0 auto 8px;
  font-style: italic;
  color: #fff;
}
@media (max-width: 425px) {
  .card__header-title {
    font-size: 16px;
    opacity: 1;
  }
}
.card__header-title .address {
  display: none;
}
@media (max-width: 425px) {
  .card__header-title .address {
    display: inline-block;
  }
}
@media (max-width: 425px) {
  .card__header {
    border-radius: 0;
  }
}
.card__content {
  padding: 56px 48px;
}
@media (max-width: 780px) {
  .card__content {
    padding: 40px 16px;
  }
}
.card__content-title {
  margin-bottom: 24px;
}
@media (max-width: 425px) {
  .card__content-title {
    margin-bottom: 16px;
    font-size: 24px;
  }
}
.card__describe {
  position: relative;
  margin-top: 15px;
  padding: 32px;
  background-color: var(--element-background);
  border-radius: 10px;
}
@media (max-width: 600px) {
  .card__describe {
    padding: 24px 20px;
  }
}
.card__describe:after {
  content: "";
  position: absolute;
  top: -10px;
  left: 50%;
  width: 30px;
  height: 30px;
  display: block;
  margin-left: -15px;
  background-color: var(--element-background);
  border-radius: 1px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.card__describe .btn-outline {
  margin: 0 auto;
  background-color: #fff;
}
.card__footer {
  padding: 20px 50px;
  border-radius: 0 0 9px 9px;
}
@media (max-width: 425px) {
  .card__footer {
    padding: 20px;
  }
}

.dark-wrapper {
  position: relative;
  padding: 32px;
  border-radius: 10px;
  background-color: var(--element-background);
}
.dark-wrapper:before {
  content: "";
  position: absolute;
  top: -11px;
  left: 50%;
  width: 22px;
  height: 22px;
  display: block;
  margin-left: -11px;
  background-color: var(--element-background);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
@media (max-width: 425px) {
  .dark-wrapper {
    padding-right: 30px;
    padding-left: 30px;
  }
}

button {
  position: relative;
  display: inline-block;
  border: none;
  text-align: center;
  outline: none;
  cursor: pointer;
}

select {
  outline: none;
}

.btn {
  padding: 12px 24px;
  border-radius: var(--btn-radius);
  font-family: var(--secondary-font-family);
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  font-size: 16px;
  background-color: var(--primary-color-10pc);
  color: var(--primary-color);
  line-height: 1;
  transition: background 0.4s ease, color 0.4s ease;
}
.btn:hover, .btn:active {
  background-color: var(--primary-color-20pc);
}
.btn:focus:before {
  content: "";
  position: absolute;
  top: -3px;
  left: -3px;
  right: -3px;
  bottom: -3px;
  display: block;
  border-radius: var(--btn-radius);
  border: 2px solid var(--primary-color);
}
.btn:focus:not(.focus-visible):before {
  border: none;
}
.btn:disabled {
  background-color: var(--primary-color-10pc) !important;
  color: var(--primary-color-30pc) !important;
  cursor: not-allowed !important;
}
.btn::-moz-focus-inner {
  border: 0;
}
.btn-primary {
  background-color: var(--btn-background);
  color: var(--btn-color);
}
.btn-primary:hover, .btn-primary:active {
  background-color: var(--btn-background-80pc);
}
.btn-primary:disabled {
  background-color: var(--btn-background-30pc) !important;
  color: var(--btn-color-30pc) !important;
}
.btn-primary:focus:before {
  border: 2px solid var(--btn-background-border);
}
.btn-primary:focus:not(.focus-visible):before {
  border: none;
}
.btn-danger {
  background-color: var(--danger-background);
  color: #fff;
}
.btn-danger:hover, .btn-danger:active {
  background-color: var(--btn-background-80pc);
  color: rgba(255, 255, 255, 0.8);
}
.btn-danger:disabled {
  background-color: var(--btn-background-30pc) !important;
  color: rgba(255, 255, 255, 0.3) !important;
}
.btn-outline {
  padding: 8px 16px;
  background-color: transparent;
  border-radius: 15px;
  border: 1px solid #b8cbd9;
  font-size: 14px;
  color: var(--light-font);
  transition: color 0.4s ease, border-color 0.4s ease;
}
.btn-outline:hover, .btn-outline:active {
  background-color: #fff;
  border-color: var(--primary-color);
  color: var(--primary-color);
}
.btn-outline:focus:before {
  border-radius: var(--btn-radius);
}
.btn-outline:disabled {
  color: var(--light-font-50pc);
}
.btn-outline:disabled:hover, .btn-outline:disabled:active {
  border-color: #b8cbd9;
}
.btn-ghost-blue {
  padding: 8px 16px;
  background-color: #fff;
  border-radius: var(--btn-radius);
  border: 1px solid var(--primary-color);
  font-size: 14px;
  color: var(--primary-color);
  transition: color 0.4s ease, border-color 0.4s ease, background-color 0.4s ease;
}
.btn-ghost-blue:hover, .btn-ghost-blue:active {
  background-color: var(--primary-color-10pc);
}
.btn-ghost-blue:disabled {
  border-color: var(--primary-color-50pc);
  color: var(--primary-color-50pc);
}
.btn-lg {
  padding: 19px 32px;
  border-radius: var(--btn-radius);
  font-weight: 600;
  font-size: 18px;
}
.btn-lg:focus:before {
  border-radius: var(--btn-radius);
}
.btn-lg.btn-outline {
  padding-top: 18px;
  padding-bottom: 18px;
  font-weight: normal;
}
.btn-block {
  display: block;
  width: 100%;
}
.btn-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.btn-group {
  margin-left: -8px;
  margin-right: -8px;
}
.btn-group .btn {
  margin: 0 8px;
}

.btn-link {
  position: relative;
  display: inline-block;
  background-color: transparent;
  font-family: var(--secondary-font-family);
  outline: none;
  line-height: 1.4;
  font-size: 16px;
  color: var(--light-font);
  cursor: pointer;
  transition: opacity 0.4s ease;
}
.btn-link:hover, .btn-link:active {
  opacity: 0.7;
}
.btn-link.disabled, .btn-link:disabled {
  opacity: 0.5 !important;
  cursor: not-allowed !important;
}
.btn-link--primary {
  color: var(--primary-color);
}
.btn-link--danger {
  color: var(--danger-color);
}
.btn-link.active {
  color: var(--primary-color-60pc);
}

.btn-tabs {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 480px;
  margin: 0 auto;
  padding: 4px;
  border-radius: 8px;
  background-color: #f4f6f7;
  overflow: hidden;
  font-family: var(--secondary-font-family);
}
.btn-tabs--interest-adds {
  max-width: 100%;
  padding: 0;
  border-radius: 7px 7px 0 0;
  background-color: transparent;
  overflow: unset;
}
.btn-tabs--interest-adds .btn-tabs__item {
  padding: 8px;
  border-radius: 0;
  border-left: 1px solid #e6e9eb;
}
.btn-tabs--interest-adds .btn-tabs__item:first-child {
  border-top-left-radius: 7px;
  border-left: none;
}
.btn-tabs--interest-adds .btn-tabs__item:last-child {
  border-top-right-radius: 7px;
}
.btn-tabs--interest-adds .btn-tabs__item.active {
  box-shadow: none;
  background-color: #f4f6f7;
  color: var(--dark-font);
}
.btn-tabs--interest-adds .btn-tabs__item.active:after {
  content: "";
  position: absolute;
  bottom: -9px;
  left: 50%;
  display: block;
  width: 10px;
  height: 10px;
  border-right: 1px solid #e6e9eb;
  border-bottom: 1px solid #e6e9eb;
  background-color: #f4f6f7;
  transform: rotate(45deg) translateX(-50%);
}
.btn-tabs--interest-adds .btn-tabs__item .term {
  font-weight: normal;
  line-height: 1.2;
  font-size: 16px;
  color: var(--dark-font);
}
.btn-tabs--interest-adds .btn-tabs__item .number {
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
}
.btn-tabs__item {
  flex: 1;
  padding: 12px;
  background: transparent;
  border-radius: 5px;
  line-height: 1;
  font-size: 16px;
  font-weight: 500;
  color: var(--dark-font);
  transition: color 0.4s ease, background 0.4s ease;
}
@media (max-width: 768px) {
  .btn-tabs__item {
    padding: 10px 5px;
    line-height: 16px;
    font-size: 14px;
  }
}
.btn-tabs__item:hover {
  color: #2681ff;
}
.btn-tabs__item.active {
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(64, 96, 128, 0.4);
  color: #2681ff;
}
.btn-tabs__item:first-child {
  border-left: none;
}
.btn-tabs__loading {
  max-width: 360px;
  margin-top: 32px;
  border-radius: 5px;
  border-color: #b8cbd9;
}
.btn-tabs__loading .btn-tabs__item {
  border-color: #b8cbd9;
  color: #b8cbd9;
}
.btn-tabs__loading .btn-tabs__item:hover {
  background-color: rgba(184, 203, 217, 0.2);
}
.btn-tabs__loading .btn-tabs__item {
  padding: 11px;
  font-family: Mukta;
}

/* Main Variables */
:root {
  --main-font-family: Mukta, sans-serif;
  --secondary-font-family: Figtree, sans-serif;
  --primary-color: #b8cbd9;
  --danger-color: #c70039;
  --dark-font: #1d1d1f;
  --light-font: #6c7680;
  --danger-color-70pc: rgba(199, 0, 57, 0.7);
  --main-background: #ebebeb;
  --danger-background: #ff2638;
  --danger-background-80pc: rgba(255, 38, 56, 0.8);
  --danger-background-30pc: rgba(255, 38, 56, 0.3);
  --form-error: #ffa926;
  --toastr-success: #22b53a;
  --btn-radius: 5px;
  --avatar-radius: 5px;
  --lpt-paid-principal: #0c46a0;
  --lpt-future-interest: #f4f6f7;
  --lpt-paid-interest: #2681ff;
}

.form-group {
  margin-top: 16px;
  text-align: left;
}
.form-group.without-label {
  margin-top: auto;
}

.form-control {
  display: block;
  width: 100%;
  height: 56px;
  padding: 0 20px;
  margin-top: 8px;
  border: 1px solid #e6e9eb;
  background-color: #fff;
  border-radius: 5px;
  transition: border-color 0.2s;
  appearance: none;
  line-height: 1.33;
  font-size: 18px;
  font-weight: normal;
  color: var(--dark-font);
}
.form-control::placeholder {
  color: var(--legend-font);
}
.form-control:focus {
  border-color: var(--primary-color);
}
.form-control:disabled {
  background-color: #eff4f7;
  border-color: #ced4d9;
  color: #a9b0b8;
}
.form-control.error, .form-control.ng-invalid.ng-dirty {
  border-color: var(--form-error);
}
.form-control.error ~ .invalid-tooltip, .form-control.ng-invalid.ng-dirty ~ .invalid-tooltip {
  display: flex;
}

.invalid-tooltip {
  position: relative;
  display: none;
  margin-top: 6px;
  padding: 3px 15px 3px 5px;
  border-radius: 5px;
  background-color: var(--form-error);
  font-size: 14px;
  line-height: 24px;
  color: var(--dark-font);
}
@media (max-width: 425px) {
  .invalid-tooltip {
    margin-right: 50px;
  }
}
.invalid-tooltip:before {
  content: "";
  display: block;
  min-width: 24px;
  height: 24px;
  margin-right: 5px;
  background-image: url("/assets/icons/warning.svg");
}
.invalid-tooltip:after {
  content: "";
  position: absolute;
  top: -3px;
  left: 13px;
  width: 6px;
  height: 6px;
  display: block;
  background-color: var(--form-error);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.input-message {
  position: relative;
  flex: 1 1 100%;
  margin-top: 5px;
  padding: 0;
  font-size: 12px;
  line-height: 1.5;
}
.input-message.error {
  color: var(--form-error);
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -7px;
  margin-left: -7px;
}

.form-col {
  width: 100%;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  padding-right: 7px;
  padding-left: 7px;
}
@media (max-width: 780px) {
  .form-col {
    flex-basis: 100%;
  }
}

.form-select {
  width: 100%;
  height: 56px;
  margin-top: 8px;
  padding: 10px 20px;
  border: 1px solid #e6e9eb;
  border-radius: 5px;
  background-image: url("/assets/icons/dropdown-arrow-grey.svg");
  background-repeat: no-repeat;
  background-position: right 15px center;
  font-size: 18px;
  color: var(--dark-font);
  background-color: #fff;
  appearance: none;
}
.form-select:focus {
  border-color: var(--primary-color);
  background-image: url("/assets/icons/dropdown-arrow-blue.svg");
}
.form-select.green:focus {
  background-image: url("/assets/icons/dropdown-arrow-green.svg");
}

.form-switch {
  position: relative;
  flex: 0 0 35px;
  width: 35px;
  height: 20px;
  margin-bottom: 0;
  margin-right: 8px;
  border-radius: 15px;
  background-color: var(--primary-color-20pc);
  cursor: pointer;
}
.form-switch__group {
  margin-top: 16px;
  display: flex;
  align-items: center;
}
.form-switch__slider {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #fff;
  transition: all 0.2s linear;
  z-index: 2;
}
.form-switch__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  border-radius: 15px;
  opacity: 1;
  background-color: transparent;
  transition: all 0.2s linear;
  z-index: 1;
}
.form-switch__control {
  visibility: hidden;
}
.form-switch__control:checked ~ .form-switch__slider {
  left: 100%;
  margin-left: -18px;
}
.form-switch__control:checked ~ .form-switch__bg {
  background-color: var(--primary-color);
}
.form-switch__control:disabled ~ .form-switch__slider {
  cursor: not-allowed;
}
.form-switch__control:disabled ~ .form-switch__bg {
  background: #fff;
  opacity: 0.4;
}
.form-switch__control:disabled:checked ~ .form-switch__bg {
  background-color: var(--primary-color);
}

.max-200 {
  max-width: 200px !important;
}

.min-200 {
  min-width: 200px !important;
}

.max-230 {
  max-width: 230px !important;
}

.min-230 {
  min-width: 230px !important;
}

.max-565 {
  max-width: 565px !important;
}

.min-565 {
  min-width: 565px !important;
}

.max-630 {
  max-width: 630px !important;
}

.min-630 {
  min-width: 630px !important;
}

.max-770 {
  max-width: 770px !important;
}

.min-770 {
  min-width: 770px !important;
}

.m-t-5 {
  margin-top: 5px !important;
}

.p-t-5 {
  padding-top: 5px !important;
}

.m-b-5 {
  margin-bottom: 5px !important;
}

.p-b-5 {
  padding-bottom: 5px !important;
}

.m-l-5 {
  margin-left: 5px !important;
}

.p-l-5 {
  padding-left: 5px !important;
}

.m-r-5 {
  margin-right: 5px !important;
}

.p-r-5 {
  padding-right: 5px !important;
}

.m-t-8 {
  margin-top: 8px !important;
}

.p-t-8 {
  padding-top: 8px !important;
}

.m-b-8 {
  margin-bottom: 8px !important;
}

.p-b-8 {
  padding-bottom: 8px !important;
}

.m-l-8 {
  margin-left: 8px !important;
}

.p-l-8 {
  padding-left: 8px !important;
}

.m-r-8 {
  margin-right: 8px !important;
}

.p-r-8 {
  padding-right: 8px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.p-t-10 {
  padding-top: 10px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.p-b-10 {
  padding-bottom: 10px !important;
}

.m-l-10 {
  margin-left: 10px !important;
}

.p-l-10 {
  padding-left: 10px !important;
}

.m-r-10 {
  margin-right: 10px !important;
}

.p-r-10 {
  padding-right: 10px !important;
}

.m-t-15 {
  margin-top: 15px !important;
}

.p-t-15 {
  padding-top: 15px !important;
}

.m-b-15 {
  margin-bottom: 15px !important;
}

.p-b-15 {
  padding-bottom: 15px !important;
}

.m-l-15 {
  margin-left: 15px !important;
}

.p-l-15 {
  padding-left: 15px !important;
}

.m-r-15 {
  margin-right: 15px !important;
}

.p-r-15 {
  padding-right: 15px !important;
}

.m-t-16 {
  margin-top: 16px !important;
}

.p-t-16 {
  padding-top: 16px !important;
}

.m-b-16 {
  margin-bottom: 16px !important;
}

.p-b-16 {
  padding-bottom: 16px !important;
}

.m-l-16 {
  margin-left: 16px !important;
}

.p-l-16 {
  padding-left: 16px !important;
}

.m-r-16 {
  margin-right: 16px !important;
}

.p-r-16 {
  padding-right: 16px !important;
}

.m-t-18 {
  margin-top: 18px !important;
}

.p-t-18 {
  padding-top: 18px !important;
}

.m-b-18 {
  margin-bottom: 18px !important;
}

.p-b-18 {
  padding-bottom: 18px !important;
}

.m-l-18 {
  margin-left: 18px !important;
}

.p-l-18 {
  padding-left: 18px !important;
}

.m-r-18 {
  margin-right: 18px !important;
}

.p-r-18 {
  padding-right: 18px !important;
}

.m-t-20 {
  margin-top: 20px !important;
}

.p-t-20 {
  padding-top: 20px !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.p-b-20 {
  padding-bottom: 20px !important;
}

.m-l-20 {
  margin-left: 20px !important;
}

.p-l-20 {
  padding-left: 20px !important;
}

.m-r-20 {
  margin-right: 20px !important;
}

.p-r-20 {
  padding-right: 20px !important;
}

.m-t-24 {
  margin-top: 24px !important;
}

.p-t-24 {
  padding-top: 24px !important;
}

.m-b-24 {
  margin-bottom: 24px !important;
}

.p-b-24 {
  padding-bottom: 24px !important;
}

.m-l-24 {
  margin-left: 24px !important;
}

.p-l-24 {
  padding-left: 24px !important;
}

.m-r-24 {
  margin-right: 24px !important;
}

.p-r-24 {
  padding-right: 24px !important;
}

.m-t-25 {
  margin-top: 25px !important;
}

.p-t-25 {
  padding-top: 25px !important;
}

.m-b-25 {
  margin-bottom: 25px !important;
}

.p-b-25 {
  padding-bottom: 25px !important;
}

.m-l-25 {
  margin-left: 25px !important;
}

.p-l-25 {
  padding-left: 25px !important;
}

.m-r-25 {
  margin-right: 25px !important;
}

.p-r-25 {
  padding-right: 25px !important;
}

.m-t-30 {
  margin-top: 30px !important;
}

.p-t-30 {
  padding-top: 30px !important;
}

.m-b-30 {
  margin-bottom: 30px !important;
}

.p-b-30 {
  padding-bottom: 30px !important;
}

.m-l-30 {
  margin-left: 30px !important;
}

.p-l-30 {
  padding-left: 30px !important;
}

.m-r-30 {
  margin-right: 30px !important;
}

.p-r-30 {
  padding-right: 30px !important;
}

.m-t-32 {
  margin-top: 32px !important;
}

.p-t-32 {
  padding-top: 32px !important;
}

.m-b-32 {
  margin-bottom: 32px !important;
}

.p-b-32 {
  padding-bottom: 32px !important;
}

.m-l-32 {
  margin-left: 32px !important;
}

.p-l-32 {
  padding-left: 32px !important;
}

.m-r-32 {
  margin-right: 32px !important;
}

.p-r-32 {
  padding-right: 32px !important;
}

.m-t-35 {
  margin-top: 35px !important;
}

.p-t-35 {
  padding-top: 35px !important;
}

.m-b-35 {
  margin-bottom: 35px !important;
}

.p-b-35 {
  padding-bottom: 35px !important;
}

.m-l-35 {
  margin-left: 35px !important;
}

.p-l-35 {
  padding-left: 35px !important;
}

.m-r-35 {
  margin-right: 35px !important;
}

.p-r-35 {
  padding-right: 35px !important;
}

.m-t-40 {
  margin-top: 40px !important;
}

.p-t-40 {
  padding-top: 40px !important;
}

.m-b-40 {
  margin-bottom: 40px !important;
}

.p-b-40 {
  padding-bottom: 40px !important;
}

.m-l-40 {
  margin-left: 40px !important;
}

.p-l-40 {
  padding-left: 40px !important;
}

.m-r-40 {
  margin-right: 40px !important;
}

.p-r-40 {
  padding-right: 40px !important;
}

.m-t-45 {
  margin-top: 45px !important;
}

.p-t-45 {
  padding-top: 45px !important;
}

.m-b-45 {
  margin-bottom: 45px !important;
}

.p-b-45 {
  padding-bottom: 45px !important;
}

.m-l-45 {
  margin-left: 45px !important;
}

.p-l-45 {
  padding-left: 45px !important;
}

.m-r-45 {
  margin-right: 45px !important;
}

.p-r-45 {
  padding-right: 45px !important;
}

.m-t-48 {
  margin-top: 48px !important;
}

.p-t-48 {
  padding-top: 48px !important;
}

.m-b-48 {
  margin-bottom: 48px !important;
}

.p-b-48 {
  padding-bottom: 48px !important;
}

.m-l-48 {
  margin-left: 48px !important;
}

.p-l-48 {
  padding-left: 48px !important;
}

.m-r-48 {
  margin-right: 48px !important;
}

.p-r-48 {
  padding-right: 48px !important;
}

.m-t-50 {
  margin-top: 50px !important;
}

.p-t-50 {
  padding-top: 50px !important;
}

.m-b-50 {
  margin-bottom: 50px !important;
}

.p-b-50 {
  padding-bottom: 50px !important;
}

.m-l-50 {
  margin-left: 50px !important;
}

.p-l-50 {
  padding-left: 50px !important;
}

.m-r-50 {
  margin-right: 50px !important;
}

.p-r-50 {
  padding-right: 50px !important;
}

.m-t-54 {
  margin-top: 54px !important;
}

.p-t-54 {
  padding-top: 54px !important;
}

.m-b-54 {
  margin-bottom: 54px !important;
}

.p-b-54 {
  padding-bottom: 54px !important;
}

.m-l-54 {
  margin-left: 54px !important;
}

.p-l-54 {
  padding-left: 54px !important;
}

.m-r-54 {
  margin-right: 54px !important;
}

.p-r-54 {
  padding-right: 54px !important;
}

.m-t-56 {
  margin-top: 56px !important;
}

.p-t-56 {
  padding-top: 56px !important;
}

.m-b-56 {
  margin-bottom: 56px !important;
}

.p-b-56 {
  padding-bottom: 56px !important;
}

.m-l-56 {
  margin-left: 56px !important;
}

.p-l-56 {
  padding-left: 56px !important;
}

.m-r-56 {
  margin-right: 56px !important;
}

.p-r-56 {
  padding-right: 56px !important;
}

.m-t-65 {
  margin-top: 65px !important;
}

.p-t-65 {
  padding-top: 65px !important;
}

.m-b-65 {
  margin-bottom: 65px !important;
}

.p-b-65 {
  padding-bottom: 65px !important;
}

.m-l-65 {
  margin-left: 65px !important;
}

.p-l-65 {
  padding-left: 65px !important;
}

.m-r-65 {
  margin-right: 65px !important;
}

.p-r-65 {
  padding-right: 65px !important;
}

.m-t-72 {
  margin-top: 72px !important;
}

.p-t-72 {
  padding-top: 72px !important;
}

.m-b-72 {
  margin-bottom: 72px !important;
}

.p-b-72 {
  padding-bottom: 72px !important;
}

.m-l-72 {
  margin-left: 72px !important;
}

.p-l-72 {
  padding-left: 72px !important;
}

.m-r-72 {
  margin-right: 72px !important;
}

.p-r-72 {
  padding-right: 72px !important;
}

.m-t-75 {
  margin-top: 75px !important;
}

.p-t-75 {
  padding-top: 75px !important;
}

.m-b-75 {
  margin-bottom: 75px !important;
}

.p-b-75 {
  padding-bottom: 75px !important;
}

.m-l-75 {
  margin-left: 75px !important;
}

.p-l-75 {
  padding-left: 75px !important;
}

.m-r-75 {
  margin-right: 75px !important;
}

.p-r-75 {
  padding-right: 75px !important;
}

.m-t-80 {
  margin-top: 80px !important;
}

.p-t-80 {
  padding-top: 80px !important;
}

.m-b-80 {
  margin-bottom: 80px !important;
}

.p-b-80 {
  padding-bottom: 80px !important;
}

.m-l-80 {
  margin-left: 80px !important;
}

.p-l-80 {
  padding-left: 80px !important;
}

.m-r-80 {
  margin-right: 80px !important;
}

.p-r-80 {
  padding-right: 80px !important;
}

/* Main Variables */
:root {
  --main-font-family: Mukta, sans-serif;
  --secondary-font-family: Figtree, sans-serif;
  --primary-color: #b8cbd9;
  --danger-color: #c70039;
  --dark-font: #1d1d1f;
  --light-font: #6c7680;
  --danger-color-70pc: rgba(199, 0, 57, 0.7);
  --main-background: #ebebeb;
  --danger-background: #ff2638;
  --danger-background-80pc: rgba(255, 38, 56, 0.8);
  --danger-background-30pc: rgba(255, 38, 56, 0.3);
  --form-error: #ffa926;
  --toastr-success: #22b53a;
  --btn-radius: 5px;
  --avatar-radius: 5px;
  --lpt-paid-principal: #0c46a0;
  --lpt-future-interest: #f4f6f7;
  --lpt-paid-interest: #2681ff;
}

.input-group {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  margin-top: 24px;
}
.input-group .input-group-prepend {
  z-index: 1;
  position: absolute;
}
.input-group .form-control {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  padding: 0 96px;
  margin: 0;
  border-radius: 50px;
  border-color: #b8cbd9;
  background-color: #f4f6f7;
  transition: background-color 0.2s ease-in;
}
.input-group .form-control:focus, .input-group .form-control:not(:placeholder-shown) {
  background-color: transparent;
}

.input-group-append {
  position: absolute;
  right: 0;
  height: 56px;
  width: 56px;
  padding: 4px;
}
.input-group-append button {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #ffa926;
}
.input-group-append button:before {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  margin-left: 2px;
  background-image: url("/assets/icons/airplane-2.svg");
  background-repeat: no-repeat;
  background-position: center center;
  transition: background 0.2s;
}
.input-group-append button.is-loading:before {
  margin-left: 0;
  background-image: url("/assets/icons/loop.svg");
  animation: rotate-360 2s linear infinite;
}

@keyframes rotate-360 {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.loading-dots {
  position: relative;
  display: block;
  text-align: center;
  padding: 0 5px;
}
.loading-dots__item {
  width: 8px;
  height: 8px;
  margin: 5px 2px;
  border-radius: 50%;
  border: 4px solid #b8cbd9;
  background-color: unset;
  animation: loading-dots-bounce-delay 1.4s infinite ease-in-out both;
}
.loading-dots__item--1 {
  animation-delay: -0.32s;
}
.loading-dots__item--2 {
  animation-delay: -0.16s;
}
.loading-dots--lg .loading-dots__item {
  width: 22px;
  height: 22px;
}
.loading-dots--content .loading-dots__item {
  display: inline-block;
  width: 22px;
  height: 22px;
}

@keyframes loading-dots-bounce-delay {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
.toast-container {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  z-index: 101;
  top: 0;
  right: 0;
}

.notify {
  width: 100%;
  padding: 20px 0;
  top: 0;
  right: 0;
  background-color: #2681ff;
}
.notify.toast-success {
  background-color: var(--toastr-success);
}
.notify.toast-warning {
  background-color: var(--danger-background);
}
.notify.toast-warning .toast-close-button {
  position: absolute;
  right: 0.5em;
  top: 1.5em;
  width: 15px;
  height: 15px;
  padding: 0;
  margin: 0;
  background-color: transparent;
  background-image: url("/assets/icons/close.svg");
}
.notify.toast-warning .toast-close-button:hover, .notify.toast-warning .toast-close-button:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}
.notify.toast-warning .toast-close-button span {
  display: none;
}
.notify .toast-title {
  color: #fff;
  text-align: center;
}
.notify .toast-message {
  width: 100%;
  font-size: 20px;
  color: #fff;
  text-align: center;
}

/* Main Variables */
:root {
  --main-font-family: Mukta, sans-serif;
  --secondary-font-family: Figtree, sans-serif;
  --primary-color: #b8cbd9;
  --danger-color: #c70039;
  --dark-font: #1d1d1f;
  --light-font: #6c7680;
  --danger-color-70pc: rgba(199, 0, 57, 0.7);
  --main-background: #ebebeb;
  --danger-background: #ff2638;
  --danger-background-80pc: rgba(255, 38, 56, 0.8);
  --danger-background-30pc: rgba(255, 38, 56, 0.3);
  --form-error: #ffa926;
  --toastr-success: #22b53a;
  --btn-radius: 5px;
  --avatar-radius: 5px;
  --lpt-paid-principal: #0c46a0;
  --lpt-future-interest: #f4f6f7;
  --lpt-paid-interest: #2681ff;
}

.modal {
  background-color: rgba(0, 30, 64, 0.7);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 16px;
  position: fixed;
  line-height: normal;
  z-index: 100;
}
.modal.fade-anim {
  transition: opacity 0.4s ease-in-out;
  opacity: 0;
}
.modal.fade-anim.in {
  opacity: 1;
}
.modal-open {
  overflow: hidden;
}
.modal-container {
  display: flex;
  align-items: center;
  max-width: 440px;
  height: 100vh;
  margin: 0 auto;
}
.modal-header img {
  width: 100%;
}
.modal-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  padding: 40px 32px;
  border-radius: 10px;
  width: 100%;
  background-color: #fff;
  transition: opacity 0.4s ease-in-out;
}
.modal-body {
  width: 100%;
}
.modal-body .message {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.5px;
  text-align: center;
  color: var(--dark-font);
}
.modal-close {
  position: absolute;
  cursor: pointer;
  top: 20px;
  right: 20px;
}
.modal-close svg-icon svg {
  fill: var(--legend-font) !important;
}
.modal-footer {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 24px;
}

.intro .modal-close {
  top: -50px;
  right: -50px;
}
@media (max-width: 900px) {
  .intro .modal-close {
    top: 10px;
    right: 10px;
  }
}
@media (max-width: 600px) {
  .intro .modal-content {
    padding-right: 15px;
    padding-left: 15px;
  }
}
.intro .modal-body {
  text-align: center;
}
.intro .modal-footer {
  margin-bottom: 20px;
}
.intro__icon {
  margin-top: 20px;
  margin-bottom: 30px;
}
.intro__title {
  margin-bottom: 24px;
}
.intro__message {
  max-width: 440px;
  margin: 0 auto 20px;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: var(--light-font);
}

/* Main Variables */
:root {
  --main-font-family: Mukta, sans-serif;
  --secondary-font-family: Figtree, sans-serif;
  --primary-color: #b8cbd9;
  --danger-color: #c70039;
  --dark-font: #1d1d1f;
  --light-font: #6c7680;
  --danger-color-70pc: rgba(199, 0, 57, 0.7);
  --main-background: #ebebeb;
  --danger-background: #ff2638;
  --danger-background-80pc: rgba(255, 38, 56, 0.8);
  --danger-background-30pc: rgba(255, 38, 56, 0.3);
  --form-error: #ffa926;
  --toastr-success: #22b53a;
  --btn-radius: 5px;
  --avatar-radius: 5px;
  --lpt-paid-principal: #0c46a0;
  --lpt-future-interest: #f4f6f7;
  --lpt-paid-interest: #2681ff;
}

.sidebar {
  background-color: rgba(0, 30, 64, 0.7);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  position: fixed;
  line-height: normal;
  z-index: 100;
}
.sidebar.fade {
  transition: opacity 0.4s ease-in-out;
  opacity: 0;
}
.sidebar.fade.in {
  opacity: 1;
}

.tooltip {
  font-size: 12px;
  background: rgba(13, 32, 51, 0.9) !important;
}

.tooltip-top::after {
  border-color: rgba(13, 32, 51, 0.9) transparent transparent transparent !important;
}

/* Main Variables */
:root {
  --main-font-family: Mukta, sans-serif;
  --secondary-font-family: Figtree, sans-serif;
  --primary-color: #b8cbd9;
  --danger-color: #c70039;
  --dark-font: #1d1d1f;
  --light-font: #6c7680;
  --danger-color-70pc: rgba(199, 0, 57, 0.7);
  --main-background: #ebebeb;
  --danger-background: #ff2638;
  --danger-background-80pc: rgba(255, 38, 56, 0.8);
  --danger-background-30pc: rgba(255, 38, 56, 0.3);
  --form-error: #ffa926;
  --toastr-success: #22b53a;
  --btn-radius: 5px;
  --avatar-radius: 5px;
  --lpt-paid-principal: #0c46a0;
  --lpt-future-interest: #f4f6f7;
  --lpt-paid-interest: #2681ff;
}

.sk-load__bar {
  width: 100%;
  min-height: 8px;
  border-radius: 12px;
  background-color: var(--primary-color);
  background-image: linear-gradient(275deg, #e4ebf2, #f4f7fb 49%, #dbe2ea);
  background-size: 200% auto;
  animation: skload 1s linear infinite;
}
.sk-load__bar.center {
  margin-left: auto;
  margin-right: auto;
}
.sk-load__bar.orange {
  background-image: none;
  background-color: #fff0b4;
}
.sk-load__bar.white {
  background-image: none;
  background-color: #fff;
}
.sk-load__bar--round {
  border-radius: 5px;
}
.sk-load__bar.h-16 {
  height: 16px;
}
.sk-load__bar.h-24 {
  height: 24px;
}
.sk-load__bar.h-36 {
  height: 36px;
}
.sk-load__bar.h-40 {
  height: 40px;
}
.sk-load__bar.h-56 {
  height: 56px;
}
.sk-load__bar.mw-106 {
  max-width: 106px;
}
.sk-load__bar.mw-114 {
  max-width: 114px;
}
.sk-load__bar.mw-130 {
  max-width: 130px;
}
.sk-load__bar.mw-154 {
  max-width: 154px;
}
.sk-load__bar.mw-166 {
  max-width: 166px;
}
.sk-load__bar.w-166 {
  width: 166px;
}
.sk-load__bar.mw-192 {
  max-width: 192px;
}
.sk-load__bar.mw-205 {
  max-width: 205px;
}
.sk-load__bar.mw-260 {
  max-width: 260px;
}
.sk-load__bar.mw-300 {
  max-width: 300px;
}
.sk-load__bar.mw-340 {
  max-width: 340px;
}
.sk-load__bar.mw-400 {
  max-width: 400px;
}
.sk-load__bar.mw-430 {
  max-width: 430px;
}
.sk-load__bar.mw-550 {
  max-width: 550px;
}
.sk-load__text {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(275deg, #e4ebf2, #f4f7fb 49%, #dbe2ea);
  background-size: 200% auto;
  color: var(--primary-color);
  animation: skload 1s linear infinite;
}
.sk-load__text--with-back {
  font-size: 20px;
  background-image: linear-gradient(275deg, #b8cbd9, #dce6ee 49%, #b8cbd9);
}
.sk-load__spinner {
  position: relative;
  display: block;
}
.sk-load__spinner-icon {
  width: 80px;
  height: 80px;
}
.sk-load__spinner-icon img {
  width: 100%;
  height: 100%;
  animation: skrotate 1s linear infinite;
}
.sk-load__spinner-counter {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  transform: translate(-50%, -50%) rotate(-90deg);
  text-align: center;
  font-size: 26px;
  line-height: 26px;
  font-weight: 600;
  letter-spacing: -1px;
  color: var(--primary-color);
}
.sk-load__circle {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #fff;
}
.sk-load__chart-image {
  margin: 40px 0 72px;
}
.sk-load__chart-image img {
  width: 100%;
}

@keyframes skload {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes skrotate {
  to {
    transform: rotate(360deg);
  }
}