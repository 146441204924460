// Template sample
//
// .form-group
//   label Interest rate
//   input.form-control.error(
//     type="text",
//     placeholder="Placeholder"
//   )
//   .invalid-tooltip Validation message


@import "src/assets/sass/_variables"
@import "src/assets/sass/_mixins"

.form-group
  margin-top: size(2x)
  text-align: left

  &.without-label
    margin-top: auto

.form-control
  display: block
  width: 100%
  height: 56px
  padding: 0 20px
  margin-top: size(1x)
  border: 1px solid $botticelli
  background-color: #fff
  border-radius: 5px
  transition: border-color 0.2s
  appearance: none
  line-height: 1.33
  font-size: 18px
  font-weight: normal
  color: var(--dark-font)

  &::placeholder
    color: var(--legend-font)

  &:focus
    border-color: var(--primary-color)

  &:disabled
    background-color: #eff4f7
    border-color: #ced4d9
    color: $gull-gray

  &.error,
  &.ng-invalid.ng-dirty
    border-color: var(--form-error)

    ~ .invalid-tooltip
      display: flex

.invalid-tooltip
  position: relative
  display: none
  margin-top: 6px
  padding: 3px 15px 3px 5px
  border-radius: 5px
  background-color: var(--form-error)
  font-size: 14px
  line-height: 24px
  color: var(--dark-font)

  +maxW(425px)
    margin-right: 50px

  &:before
    content: ''
    display: block
    min-width: 24px
    height: 24px
    margin-right: 5px
    background-image: url('/assets/icons/warning.svg')

  &:after
    content: ''
    position: absolute
    top: -3px
    left: 13px
    width: 6px
    height: 6px
    display: block
    background-color: var(--form-error)
    -webkit-transform: rotate(45deg)
    transform: rotate(45deg)

.input-message
  position: relative
  flex: 1 1 100%
  margin-top: 5px
  padding: 0
  font-size: 12px
  line-height: 1.5

  &.error
    color: var(--form-error)

.form-row
  display: flex
  flex-wrap: wrap
  margin-right: -7px
  margin-left: -7px

.form-col
  width: 100%
  flex-basis: 0
  flex-grow: 1
  max-width: 100%
  padding-right: 7px
  padding-left: 7px

  +maxW(780px)
    flex-basis: 100%

.form-select
  width: 100%
  height: 56px
  margin-top: size(1x)
  padding: 10px 20px
  border: 1px solid $botticelli
  border-radius: 5px
  background-image: url('/assets/icons/dropdown-arrow-grey.svg')
  background-repeat: no-repeat
  background-position: right 15px center
  font-size: 18px
  color: var(--dark-font)
  background-color: #fff
  appearance: none

  &:focus
    border-color: var(--primary-color)
    background-image: url('/assets/icons/dropdown-arrow-blue.svg')

  &.green
    &:focus
      background-image: url('/assets/icons/dropdown-arrow-green.svg')

.form-switch
  position: relative
  flex: 0 0 35px
  width: 35px
  height: 20px
  margin-bottom: 0
  margin-right: size(1x)
  border-radius: 15px
  background-color: var(--primary-color-20pc)
  cursor: pointer

  &__group
    margin-top: size(2x)
    display: flex
    align-items: center

  &__slider
    position: absolute
    top: 2px
    left: 2px
    width: 16px
    height: 16px
    border-radius: 50%
    background-color: #fff
    transition: all 0.2s linear
    z-index: 2

  &__bg
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    display: block
    border-radius: 15px
    opacity: 1
    background-color: transparent
    transition: all 0.2s linear
    z-index: 1


  &__control
    visibility: hidden

    &:checked ~ .form-switch__slider
      left: 100%
      margin-left: -18px


    &:checked ~ .form-switch__bg
      background-color: var(--primary-color)


    &:disabled ~ .form-switch__slider
      cursor: not-allowed


    &:disabled ~ .form-switch__bg
      background: #fff
      opacity: 0.4


    &:disabled:checked ~ .form-switch__bg
      background-color: var(--primary-color)
