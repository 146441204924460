@import "src/assets/sass/_variables"

.modal
  background-color: rgba(#001e40, 0.7)
  top: 0
  right: 0
  bottom: 0
  left: 0
  height: 100%
  overflow-x: hidden
  overflow-y: auto
  padding: 0 16px
  position: fixed
  line-height: normal
  z-index: 100

  &.fade-anim
    transition: opacity 0.4s ease-in-out
    opacity: 0

  &.fade-anim.in
    opacity: 1

  &-open
    overflow: hidden

  &-container
    display: flex
    align-items: center
    max-width: 440px
    height: 100vh
    margin: 0 auto

  &-header
    img
      width: 100%

  &-content
    display: flex
    flex-direction: column
    align-items: flex-start
    position: relative
    padding: 40px size(4x)
    border-radius: 10px
    width: 100%
    background-color: #fff
    transition: opacity 0.4s ease-in-out

  &-body
    width: 100%

    .message
      font-size: 20px
      font-weight: 400
      letter-spacing: 0.5px
      text-align: center
      color: var(--dark-font)

  &-close
    position: absolute
    cursor: pointer
    top: 20px
    right: 20px

    svg-icon
      svg
        fill: var(--legend-font)!important

  &-footer
    display: flex
    justify-content: center
    width: 100%
    margin-top: size(3x)

.intro
  .modal-close
    top: -50px
    right: -50px

    +maxW(900px)
      top: 10px
      right: 10px

  .modal-content
    +maxW(600px)
      padding-right: 15px
      padding-left: 15px

  .modal-body
    text-align: center

  .modal-footer
    margin-bottom: 20px

  &__icon
    margin-top: 20px
    margin-bottom: 30px

  &__title
    margin-bottom: size(3x)

  &__message
    max-width: 440px
    margin: 0 auto 20px
    font-size: 18px
    line-height: 25px
    text-align: center
    color: var(--light-font)
