.toast-container
  display: flex
  flex-direction: column
  position: fixed
  width: 100%
  // more than SimpleModal owerlay z-index
  z-index: 101
  top: 0
  right: 0


.notify
  width: 100%
  padding: 20px 0
  top: 0
  right: 0
  background-color: $clear-blue

  &.toast-success
    background-color: var(--toastr-success)

  &.toast-warning
    background-color: var(--danger-background)

    .toast-close-button
      position: absolute
      right: 0.5em
      top: 1.5em
      width: 15px
      height: 15px
      padding: 0
      margin: 0
      background-color: transparent
      background-image: url('/assets/icons/close.svg')

      &:hover,
      &:focus
        color: #000
        text-decoration: none
        cursor: pointer
        opacity: 0.4

      span
        display: none

  .toast-title
    color: $white
    text-align: center

  .toast-message
    width: 100%
    font-size: 20px
    color: $white
    text-align: center
