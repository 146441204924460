
// .max-565 which gives max-width 565 pixels.
// The first word is "max" or "min" for max-width or min-width
// Secoond letter is the number of width in pixels. Adjust the amounts generated by editing the $widthamounts variable below.


$widthamounts: (200, 230, 565, 630, 770) // Adjust this to include the pixel amounts you need.
$types: (max, min) // Leave this variable alone

@each $width in $widthamounts
  @each $type in $types
    .#{$type}-#{$width}
      #{$type}-width: #{$width}px !important
