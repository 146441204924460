@import "src/assets/sass/_variables"

.sidebar
  background-color: rgba(#001e40, 0.7)
  top: 0
  right: 0
  bottom: 0
  left: 0
  height: 100%
  overflow-x: hidden
  overflow-y: auto
  padding: 0
  position: fixed
  line-height: normal
  z-index: 100

  &.fade
    transition: opacity 0.4s ease-in-out
    opacity: 0

  &.fade
    &.in
      opacity: 1
