// Media Mixins
@mixin maxW($maxw)
  @media (max-width: $maxw)
    @content

@mixin minW($min)
  @media (min-width: $min)
    @content

@mixin keyframes($animation-name)
  @keyframes #{$animation-name}
    @content


@mixin keyframesFrom($animation-name, $steps)
  @keyframes #{$animation-name}
    @for $i from 1 through $steps
      #{$i * (100%/$steps)}
        transform: translate3d(0,-1 * $i * (100%/$steps),0)
