.loading-dots
  position: relative
  display: block
  text-align: center
  padding: 0 5px
  $this: &

  &__item
    width: 8px
    height: 8px
    margin: 5px 2px
    border-radius: 50%
    border: 4px solid $buttermilk
    background-color: unset
    animation: loading-dots-bounce-delay 1.4s infinite ease-in-out both

    &--1
      animation-delay: -0.32s

    &--2
      animation-delay: -0.16s

  &--lg
    #{$this}__item
      width: 22px
      height: 22px

  &--content
    .loading-dots
      &__item
        display: inline-block
        width: 22px
        height: 22px


@keyframes loading-dots-bounce-delay
  0%, 80%, 100%
    transform: scale(0)

  40%
    transform: scale(1)
