@import "src/assets/sass/_variables"

.color-primary
  color: var(--primary-color) !important

.color-danger
  color: var(--danger-color) !important

.color-white
  color: #fff !important
