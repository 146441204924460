@import "src/assets/sass/_mixins"
@import "src/assets/sass/_variables"

.container
  max-width: 832px
  margin: 0 auto
  padding: 80px 20px 0

  +maxW(425px)
    padding: 0

.card
  position: relative
  margin-bottom: size(4x)
  background-color: #fff
  border-radius: 10px
  box-shadow: 0 10px 35px 0 rgba(2, 36, 71, 0.05), 0 5px 15px 0 rgba(2, 36, 71, 0.05)

  &.tip-label:before
    content: ''
    position: absolute
    top: 35px
    left: -13px
    width: 64px
    height: 47px
    background-image: url('/assets/icons/tip-label.svg')
    background-repeat: no-repeat

  +maxW(425px)
    border-radius: 0

    &.tip-label:before
      top: -12px
      left: 50%
      width: 73px
      height: 34px
      margin-left: -36px
      background-image: url('/assets/icons/tip-label-mobile.svg')

  &__header
    position: relative
    padding: size(8x) 20px size(5x)
    border-radius: 9px 9px 0 0

    &-user
      position: absolute
      top: -45px
      left: 50%
      width: 90px
      height: 90px
      padding: 5px
      border-radius: 50%
      overflow: hidden
      background-color: var(--main-background)
      transform: translateX(-50%)

    &-title
      max-width: 600px
      margin: 0 auto size(1x)
      font-style: italic
      color: $white

      +maxW(425px)
        font-size: 16px
        opacity: 1

      .address
        display: none

        +maxW(425px)
          display: inline-block

    +maxW(425px)
      border-radius: 0

  &__content
    padding: size(7x) size(6x)

    +maxW(780px)
      padding: size(5x) size(2x)

    &-title
      margin-bottom: size(3x)

      +maxW(425px)
        margin-bottom: size(2x)
        font-size: 24px

  &__describe
    position: relative
    margin-top: 15px
    padding: size(4x)
    background-color: var(--element-background)
    border-radius: 10px

    +maxW(600px)
      padding: size(3x) 20px

    &:after
      content: ''
      position: absolute
      top: -10px
      left: 50%
      width: 30px
      height: 30px
      display: block
      margin-left: -15px
      background-color: var(--element-background)
      border-radius: 1px
      -webkit-transform: rotate(45deg)
      transform: rotate(45deg)

    .btn-outline
      margin: 0 auto
      background-color: #fff

  &__footer
    padding: 20px 50px
    border-radius: 0 0 9px 9px

    +maxW(425px)
      padding: 20px

.dark-wrapper
  position: relative
  padding: size(4x)
  border-radius: 10px
  background-color: var(--element-background)

  &:before
    content: ''
    position: absolute
    top: -11px
    left: 50%
    width: 22px
    height: 22px
    display: block
    margin-left: -11px
    background-color: var(--element-background)
    -webkit-transform: rotate(45deg)
    transform: rotate(45deg)

  +maxW(425px)
    padding-right: 30px
    padding-left: 30px
