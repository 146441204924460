@import "src/assets/sass/_variables"
@import "src/assets/sass/_mixins"

h1, h2, h3, h4, h5
  font-weight: normal
  color: var(--dark-font)

h1
  line-height: 1.2
  font-weight: 600
  font-size: 40px

h2
  line-height: 1.4
  font-weight: 600
  font-size: 30px

  +maxW(768px)
    line-height: 1.19
    font-size: 26px

h3
  line-height: 1.42
  font-size: 24px

  +maxW(768px)
    line-height: 1.18
    font-size: 22px

h4
  line-height: 1.4
  font-size: 20px

  +maxW(768px)
    line-height: 1.22
    font-size: 18px

h5
  line-height: 1.38
  font-size: 16px

  +maxW(768px)
    line-height: 1.19
