ul
  list-style: none

  &.list-unstyled
    padding-left: 0

  &.list-inline
    display: flex
    padding-left: 0

    li
      margin-right: 15px
