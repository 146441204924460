@import "src/assets/sass/_variables"

p
  font-weight: normal
  color: var(--light-font)

  &.text
    line-height: 1.38
    font-size: 16px

    &--lg
      line-height: 1.39
      font-size: 18px

.legend-text
  line-height: 1.14
  font-size: 13px
  color: var(--light-font)

.disclaimer-text
  line-height: 1.36
  font-size: 11px
  color: var(--legend-font)

  a
    text-decoration: underline
    color: $clear-blue

.caption-text
  line-height: 1.23
  font-size: 13px
  color: var(--light-font)

.text-overflow
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap

.text-italic
  font-style: italic

.f-w-600
  font-weight: 600

.f-w-900
  font-weight: 900


// .f-15-400
//   font-size: 15px
//   font-weight: 400

// .f-c-gray-main
//   color: #333940

$font_size: (11, 12, 13, 16, 18, 20, 24, 26, 30, 32, 34)
$font_weight: (normal, 400, 500, 600, 700, bold)
$font_color: ('east-bay' $east-bay, 'gull-gray' $gull-gray, 'clear-blue' $clear-blue, 'firefly' $firefly)

@each $size in $font_size
  @each $weight in $font_weight
    .f-#{$size}-#{$weight}
      font-size: #{$size}px !important
      font-weight: #{$weight} !important

@each $classname, $color in $font_color
  .f-c-#{$classname}
    color: $color !important
