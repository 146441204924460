// unknown
$rock-blue: #bac3cb
$zumthor-original: #e6f0ff
$zumthor: rgba(#2681ff, 0.1)

/* Main Variables

// Background color
$cornflower: rgba(#2681ff, 0.1)
$green-dark: #22b53a
$gray: #ebebeb
$catskill: #f4f6f7
$orange: #ffa926
$buttermilk: #b8cbd9
$strawberry: #ff2638
$twilight-blue: #0c46a0
$colors-buttermilk: #fff0b4
$casper: #ced4d9

// Elements
$botticelli: #e6e9eb

// Fonts Colors
$white: #fff
$monza: #c70039
$firefly: #1d1d1f
$east-bay: #6c7680
$gull-gray: #a9b0b8
$clear-blue: #2681ff

// Sizes
$sizes: (1x: 8px, 2x: 16px, 3x: 24px, 4x: 32px, 5x: 40px, 6x: 48px, 7x: 56px, 8x: 64px, 9x: 72px, 10x: 80px)
@function size($size)
  @return map-get($sizes, $size)

\:root
  --main-font-family: Mukta, sans-serif
  --secondary-font-family: Figtree, sans-serif
  --primary-color: #{$buttermilk}
  --danger-color: #{$monza}
  --dark-font: #{$firefly}
  --light-font: #{$east-bay}
  --danger-color-70pc: rgba(199, 0, 57, 0.7)
  --main-background: #{$gray}
  --danger-background: #{$strawberry}
  --danger-background-80pc: rgba(255, 38, 56, 0.8)
  --danger-background-30pc: rgba(255, 38, 56, 0.3)
  --form-error: #{$orange}
  --toastr-success: #{$green-dark}
  --btn-radius: 5px
  --avatar-radius: 5px
  --lpt-paid-principal: #{$twilight-blue}
  --lpt-future-interest: #{$catskill}
  --lpt-paid-interest: #{$clear-blue}
