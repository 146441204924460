@import "src/assets/sass/_variables"

input, button
  border: none

  &:focus
    outline: none

.form-label-text
  font-size: 16px
  font-weight: normal
  line-height: 1
  color: var(--light-font)

.form-value-text
  font-size: 18px
  font-weight: normal
  line-height: 1.33
  color: var(--dark-font)

.form-placeholder-text
  font-size: 18px
  font-weight: normal
  line-height: 1.33
  color: var(--legend-font)
