$radius: var(--btn-radius)

button
  position: relative
  display: inline-block
  border: none
  text-align: center
  outline: none
  cursor: pointer

select

  outline: none

.btn
  padding: 12px 24px
  border-radius: $radius
  font-family: var(--secondary-font-family)
  font-weight: 500
  font-style: normal
  font-stretch: normal
  font-size: 16px
  background-color: var(--primary-color-10pc)
  color: var(--primary-color)
  line-height: 1
  transition: background 0.4s ease, color 0.4s ease

  &:hover,
  &:active
    background-color: var(--primary-color-20pc)

  &:focus
    &:before
      content: ""
      position: absolute
      top: -3px
      left: -3px
      right: -3px
      bottom: -3px
      display: block
      border-radius: $radius
      border: 2px solid var(--primary-color)

  &:focus:not(.focus-visible)
    &:before
      border: none

  &:disabled
    background-color: var(--primary-color-10pc) !important
    color: var(--primary-color-30pc) !important
    cursor: not-allowed !important

  &::-moz-focus-inner
    border: 0

  // primary
  &-primary
    background-color: var(--btn-background)
    color: var(--btn-color)

    &:hover,
    &:active
      background-color: var(--btn-background-80pc)

    &:disabled
      background-color: var(--btn-background-30pc) !important
      color: var(--btn-color-30pc) !important

    &:focus
      &:before
        border: 2px solid var(--btn-background-border)

    &:focus:not(.focus-visible)
      &:before
        border: none


  // danger
  &-danger
    background-color: var(--danger-background)
    color: $white

    &:hover,
    &:active
      background-color: var(--btn-background-80pc)
      color: rgba($white, 0.8)

    &:disabled
      background-color: var(--btn-background-30pc) !important
      color: rgba($white, 0.3) !important

  // outline
  &-outline
    padding: 8px 16px
    background-color: transparent
    border-radius: 15px
    border: 1px solid $buttermilk
    font-size: 14px
    color: var(--light-font)
    transition: color 0.4s ease, border-color 0.4s ease

    &:hover,
    &:active
      background-color: $white
      border-color: var(--primary-color)
      color: var(--primary-color)

    &:focus
      &:before
        border-radius: $radius

    &:disabled
      color: var(--light-font-50pc)

      &:hover,
      &:active
        border-color: $buttermilk

  &-ghost-blue
    padding: 8px 16px
    background-color: $white
    border-radius: $radius
    border: 1px solid var(--primary-color)
    font-size: 14px
    color: var(--primary-color)
    transition: color 0.4s ease, border-color 0.4s ease, background-color 0.4s ease

    &:hover,
    &:active
      background-color: var(--primary-color-10pc)

    &:disabled
      border-color: var(--primary-color-50pc)
      color: var(--primary-color-50pc)

  // large size
  &-lg
    padding: 19px 32px
    border-radius: $radius
    font-weight: 600
    font-size: 18px

    &:focus
      &:before
        border-radius: $radius

    &.btn-outline
      padding-top: 18px
      padding-bottom: 18px
      font-weight: normal

  &-block
    display: block
    width: 100%

  &-center
    display: block
    margin-left: auto
    margin-right: auto

.btn-group
  margin-left: -8px
  margin-right: -8px

  .btn
    margin: 0 8px

.btn-link
  position: relative
  display: inline-block
  background-color: transparent
  font-family: var(--secondary-font-family)
  outline: none
  line-height: 1.4
  font-size: 16px
  color: var(--light-font)
  cursor: pointer
  transition: opacity 0.4s ease

  &:hover,
  &:active
    opacity: 0.7

  &.disabled,
  &:disabled
    opacity: 0.5 !important
    cursor: not-allowed !important

  &--primary
    color: var(--primary-color)

  &--danger
    color: var(--danger-color)

  &.active
    color: var(--primary-color-60pc)

.btn-tabs
  display: flex
  flex-wrap: wrap
  justify-content: center
  max-width: 480px
  margin: 0 auto
  padding: 4px
  border-radius: 8px
  background-color: $catskill
  overflow: hidden
  font-family: var(--secondary-font-family)
  $this: &

  &--interest-adds
    max-width: 100%
    padding: 0
    border-radius: 7px 7px 0 0
    background-color: transparent
    overflow: unset

    .btn-tabs__item
      padding: size(1x)
      border-radius: 0
      border-left: 1px solid $botticelli

      &:first-child
        border-top-left-radius: 7px
        border-left: none

      &:last-child
        border-top-right-radius: 7px

      &.active
        box-shadow: none
        background-color: $catskill
        color: var(--dark-font)

        &:after
          content: ''
          position: absolute
          bottom: -9px
          left: 50%
          display: block
          width: 10px
          height: 10px
          border-right: 1px solid $botticelli
          border-bottom: 1px solid $botticelli
          background-color: $catskill
          transform: rotate(45deg) translateX(-50%)

      .term
        font-weight: normal
        line-height: 1.2
        font-size: 16px
        color: var(--dark-font)

      .number
        font-size: 18px
        line-height: 22px
        font-weight: 600

  &__item
    flex: 1
    padding: 12px
    background: transparent
    border-radius: 5px
    line-height: 1
    font-size: 16px
    font-weight: 500
    color: var(--dark-font)
    transition: color 0.4s ease, background 0.4s ease

    +maxW(768px)
      padding: 10px 5px
      line-height: 16px
      font-size: 14px

    &:hover
      color: $clear-blue

    &.active
      background-color: #fff
      box-shadow: 0 2px 4px 0 rgba(64, 96, 128, 0.4)
      color: $clear-blue

    &:first-child
      border-left: none

  &__loading
    max-width: 360px
    margin-top: 32px
    border-radius: 5px
    border-color: $buttermilk

    #{$this}__item
      border-color: $buttermilk
      color: $buttermilk

      &:hover
       background-color: rgba($buttermilk, 0.2)

    .btn-tabs__item
      padding: 11px
      font-family: Mukta
