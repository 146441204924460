.no-display
  display: none

.block
  display: block

.flex
  display: flex

.flex-1
  flex: 1

  .justify-center
    justify-content: center

  .align-conter
    align-items: center
