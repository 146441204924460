@import "src/assets/sass/_variables"

.input-group
  position: relative
  display: flex
  align-items: center
  width: 100%
  max-width: 500px
  margin: 0 auto
  margin-top: size(3x)

  .input-group-prepend
    z-index: 1
    position: absolute

  .form-control
    position: relative
    flex: 1 1 auto
    width: 1%
    padding: 0 96px
    margin: 0
    border-radius: 50px
    border-color: $buttermilk
    background-color: $catskill
    transition: background-color 0.2s ease-in

    &:focus, &:not(:placeholder-shown)
      background-color: transparent

.input-group-append
  position: absolute
  right: 0
  height: 56px
  width: 56px
  padding: 4px

  button
    width: 100%
    height: 100%
    border-radius: 50%
    background-color: $orange

    &:before
      content: ''
      width: 100%
      height: 100%
      display: block
      margin-left: 2px
      background-image: url("/assets/icons/airplane-2.svg")
      background-repeat: no-repeat
      background-position: center center
      transition: background 0.2s

    &.is-loading:before
      margin-left: 0
      background-image: url("/assets/icons/loop.svg")
      animation: rotate-360 2s linear infinite

+keyframes(rotate-360)
  from
    transform: rotate(0)
  to
    transform: rotate(360deg)
