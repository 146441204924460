.number
  font-family: var(--secondary-font-family)
  font-weight: 600
  color: var(--dark-font)

  &--huge
    font-size: 50px
    line-height: 1.2

  &--lg
    font-size: 36px
    line-height: 1.19

  &--md
    font-size: 32px
    line-height: 1.19

  &--sm
    font-weight: normal
    font-size: 20px
    line-height: 1.2

    &.font-weight-600
      font-weight: 600
