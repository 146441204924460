@import "src/assets/sass/_variables"

.contact-phone
  a
    padding: 0
    font-size: 20px
    font-weight: normal
    line-height: 1.4
    color: var(--dark-font)

.contact-email
  a
    display: inline-block
    overflow: hidden
    text-overflow: ellipsis
    padding: 0
    font-size: 20px
    font-weight: normal
    line-height: 1.4
    color: var(--dark-font)
