@import "src/assets/sass/_variables"

body
  min-width: 320px
  font-size: 16px
  font-family: var(--main-font-family)
  font-weight: normal
  font-style: normal
  font-stretch: normal
  line-height: 1.5
  letter-spacing: normal
  background-color: var(--main-background)
  color: var(--light-font)
  -webkit-font-smoothing: antialiased

*,
::after,
::before
  box-sizing: border-box

.hr
  display: block
  max-width: 670px
  height: 1px
  margin: 0 auto
  border: none
  background: $buttermilk
  opacity: 0.5
