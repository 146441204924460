@import "src/assets/sass/_variables"

.sk-load
  &__bar
    width: 100%
    min-height: 8px
    border-radius: 12px
    background-color: var(--primary-color)
    background-image: linear-gradient(275deg, #e4ebf2, #f4f7fb 49%, #dbe2ea)
    background-size: 200% auto
    animation: skload 1s linear infinite

    &.center
      margin-left: auto
      margin-right: auto

    &.orange
      background-image: none
      background-color: #fff0b4

    &.white
      background-image: none
      background-color: #fff

    &--round
      border-radius: 5px

    &.h-16
      height: 16px

    &.h-24
      height: 24px

    &.h-36
      height: 36px

    &.h-40
      height: 40px

    &.h-56
      height: 56px

    &.mw-106
      max-width: 106px

    &.mw-114
      max-width: 114px

    &.mw-130
      max-width: 130px

    &.mw-154
      max-width: 154px

    &.mw-166
      max-width: 166px

    &.w-166
      width: 166px

    &.mw-192
      max-width: 192px

    &.mw-205
      max-width: 205px

    &.mw-260
      max-width: 260px

    &.mw-300
      max-width: 300px

    &.mw-340
      max-width: 340px

    &.mw-400
      max-width: 400px
    
    &.mw-430
      max-width: 430px

    &.mw-550
      max-width: 550px

  &__text
    -webkit-background-clip: text
    -webkit-text-fill-color: transparent
    background-image: linear-gradient(275deg, #e4ebf2, #f4f7fb 49%, #dbe2ea)
    background-size: 200% auto
    color: var(--primary-color)
    animation: skload 1s linear infinite

    &--with-back
      font-size: 20px
      background-image: linear-gradient(275deg, $buttermilk, #dce6ee 49%, $buttermilk)

  &__spinner
    position: relative
    display: block

    &-icon
      width: 80px
      height: 80px

      img
        width: 100%
        height: 100%
        animation: skrotate 1s linear infinite

    &-counter
      position: absolute
      top: 50%
      left: 50%
      display: block
      transform: translate(-50%, -50%) rotate(-90deg)
      text-align: center
      font-size: 26px
      line-height: 26px
      font-weight: 600
      letter-spacing: -1px
      color: var(--primary-color)

  &__circle
    width: 120px
    height: 120px
    border-radius: 50%
    background-color: #fff

  &__chart-image
    margin: 40px 0 72px

    img
      width: 100%

@keyframes skload
  0%
    background-position: 0% 50%

  50%
    background-position: 100% 50%

  100%
    background-position: 0% 50%

@keyframes skrotate
  to
    transform: rotate(360deg)
