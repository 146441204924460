@import "./titles"
@import "./numbers"
@import "./text"
@import "./text-align"
@import "./form-text"
@import "./list"
@import "./contact"
@import "./colors"

a
  text-decoration: none

h1,h2,h3,h4,h5,h6,p,ul
  margin: 0

.pseudo-link
  font-size: 22px
  color: var(--primary-color)
  opacity: 1
  transition: opacity 0.2s
  cursor: pointer

  &:hover
    opacity: 0.8
